// @ts-strict-ignore
import type { HTMLProps, ReactNode, JSX } from "react";
import { Children, isValidElement, Fragment } from "react";

import type { AviarySize } from "@aviary/types";

import * as styles from "./Arrange.styles";

type ArrangeAlignment = "start" | "end" | "center" | "stretch" | "baseline";
type ArrangeJustify = "start" | "end" | "center" | "spaceBetween" | "spaceAround" | "spaceEvenly";
type ArrangeSpacing = Exclude<AviarySize, "normal">;

interface ArrangeProps extends HTMLProps<HTMLDivElement> {
  /**
   * Adjust the vertical alignment of elements
   */
  alignment?: ArrangeAlignment;
  /**
   * Adjust the horizontal alignment of elements
   */
  justify?: ArrangeJustify;
  /**
   * Adjust the spacing of elements
   * @default medium
   */
  spacing?: ArrangeSpacing;
  /**
   * Arrange the elements vertically
   */
  isVertical?: boolean;
  /**
   * Allows wrapping onto additional rows if there is not enough space in the container
   */
  isWrapped?: boolean;
  /**
   * Allows you to reverse the order in which the elements are displayed
   */
  isReversed?: boolean;
  /**
   * Configures Arrange items to take 100% width of its parent
   *
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * Configures Arrange container to take 100% height of its parent
   *
   * @default false
   */
  isFullHeight?: boolean;
  /**
   * Children to be arranged
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/layout/Arrange
 */
const Arrange = ({
  alignment,
  spacing = "medium",
  justify,
  children,
  isVertical = false,
  isReversed = false,
  isWrapped = true,
  isFullWidth,
  isFullHeight = false,
  ...rest
}: ArrangeProps) => {
  const css = [
    styles.arrange,
    alignment && styles.alignment[alignment],
    justify && styles.justify[justify],
    isVertical && [styles.isVertical, isFullHeight && styles.fullHeight],
    styles.spacing[spacing],
    isWrapped && styles.isWrapped,
    isReversed && styles.isReversed(isVertical),
  ];

  const itemStyles = [styles.item, isFullWidth && styles.fullwidth];

  const renderItems = () => {
    return Children.map(children, child => {
      if (isValidElement(child)) {
        if (child.type === Fragment || child.type === "Symbol(react.fragment)") {
          return (child as JSX.Element).props.children.map((item, index) => (
            <div css={itemStyles} key={index}>
              {item}
            </div>
          ));
        }

        return <div css={itemStyles}>{child}</div>;
      }
    });
  };

  return (
    <div css={css} {...rest}>
      {renderItems()}
    </div>
  );
};

export { Arrange, type ArrangeJustify, type ArrangeSpacing };
