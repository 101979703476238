// @ts-strict-ignore
import { css } from "@emotion/react";

export const arrange = css`
  display: flex;
  max-width: 100%;
`;

export const fullwidth = css`
  width: 100%;
`;

export const item = css`
  max-width: 100%;
  width: fit-content;
`;

export const alignment = {
  start: css`
    align-items: flex-start;
  `,
  end: css`
    align-items: flex-end;
  `,
  center: css`
    align-items: center;
  `,
  stretch: css`
    align-items: stretch;
  `,
  baseline: css`
    align-items: baseline;
  `,
};

export const justify = {
  start: css`
    justify-content: flex-start;
  `,
  end: css`
    justify-content: flex-end;
  `,
  center: css`
    justify-content: center;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `,
  spaceAround: css`
    justify-content: space-around;
  `,
  spaceEvenly: css`
    justify-content: space-evenly;
  `,
};

// TODO: Replace these values using @aviary-tokens spacings once published to NPM
export const spacing = {
  none: css`
    gap: 0;
  `,
  xxsmall: css`
    gap: 0.25rem;
  `,
  xsmall: css`
    gap: 0.5rem;
  `,
  small: css`
    gap: 0.75rem;
  `,
  medium: css`
    gap: 1rem;
  `,
  large: css`
    gap: 1.5rem;
  `,
  xlarge: css`
    gap: 2rem;
  `,
};

export const isVertical = css`
  flex-direction: column;
`;

export const fullHeight = css`
  height: 100%;
`;

export const isWrapped = css`
  flex-wrap: wrap;
`;

export const isReversed = vertical => css`
  flex-direction: ${vertical ? "column-reverse" : "row-reverse"};
`;
